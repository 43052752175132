import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: 'Basic',
        currency_r:'₹',
        currency_d:'$',
        subtitle: 'A simple start for everyone',
        credit:600,
        unit_d:'0.20',
        unit_r:'16.66',
        total_d: '118',
        total_r:'9998',
        price_d: 23,
        price_r: 1999,
        discount:'80%',
      planBenefits_d: [
        'Included 600 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
      1. AI driven ICP identification
      2. AI driven Personalised communication
      3. Get unlimited meetings booked`, `Email reputation protection:
      1. Add subdomains to send the communications
      2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
      Emails delivery and open tracking`, `Full Access to 360 Dashboard:
      1. Learn everything about prospect and their company
      2. Sales Pitch recommendations
      3. Get DISC profile recommendations and understand behavioral personal deeply
      4. Learn what works best for this client on call and target`
    ],
      planBenefits_r: [
        'Included 600 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
        1. AI driven ICP identification
        2. AI driven Personalised communication
        3. Get unlimited meetings booked`, `Email reputation protection:
        1. Add subdomains to send the communications
        2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
        Emails delivery and open tracking`, `Full Access to 360 Dashboard:
        1. Learn everything about prospect and their company
        2. Sales Pitch recommendations
        3. Get DISC profile recommendations and understand behavioral personal deeply
        4. Learn what works best for this client on call and target`
    ],
      popular: false
    },
    startupPlan: {
      title: 'Startup',
      currency_r:'₹',
      currency_d:'$',
      subtitle: 'A simple start for early startups',
      credit:2000,
      unit_d:'0.16',
      unit_r:'13.00',
      total_d: '318',
      total_r: '25998',
      price_d: 63,
      price_r: 5199,
      discount:'80%',
      planBenefits_d: [
        'Included 2,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
      1. AI driven ICP identification
      2. AI driven Personalised communication
      3. Get unlimited meetings booked`, `Email reputation protection:
      1. Add subdomains to send the communications
      2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
      Emails delivery and open tracking`, `Full Access to 360 Dashboard:
      1. Learn everything about prospect and their company
      2. Sales Pitch recommendations
      3. Get DISC profile recommendations and understand behavioral personal deeply
      4. Learn what works best for this client on call and target`, `Get access to Buying Intent data filters`
    ],
      planBenefits_r: [
        'Included 2,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
      1. AI driven ICP identification
      2. AI driven Personalised communication
      3. Get unlimited meetings booked`, `Email reputation protection:
      1. Add subdomains to send the communications
      2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
      Emails delivery and open tracking`, `Full Access to 360 Dashboard:
      1. Learn everything about prospect and their company
      2. Sales Pitch recommendations
      3. Get DISC profile recommendations and understand behavioral personal deeply
      4. Learn what works best for this client on call and target`, `Get access to Buying Intent data filters`
    ],
      popular: false
    },
    growthPlan: {
      title: 'Growth',
      currency_r:'₹',
      currency_d:'$',
      subtitle: 'For small to medium businesses',
      credit:5000,
      unit_d:'0.12',
      unit_r:'10.00',
      total_d: '598',
      total_r: '49998',
      price_d: 119,
      price_r: 9999,
      discount:'80%',
      planBenefits_d: [
        'Included 5,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
      1. AI driven ICP identification
      2. AI driven Personalised communication
      3. Get unlimited meetings booked`, `Email reputation protection:
      1. Add subdomains to send the communications
      2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
      Emails delivery and open tracking`, `Full Access to 360 Dashboard:
      1. Learn everything about prospect and their company
      2. Sales Pitch recommendations
      3. Get DISC profile recommendations and understand behavioral personal deeply
      4. Learn what works best for this client on call and target`, `Get access to Buying Intent data filters`
    ],
      planBenefits_r: [
        'Included 5,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `Access to full AI Autopilot:
        1. AI driven ICP identification
        2. AI driven Personalised communication
        3. Get unlimited meetings booked`, `Email reputation protection:
        1. Add subdomains to send the communications
        2. Email communications from unlimited emails`, `Demographic, Firmographic and Keywords filters
        Emails delivery and open tracking`, `Full Access to 360 Dashboard:
        1. Learn everything about prospect and their company
        2. Sales Pitch recommendations
        3. Get DISC profile recommendations and understand behavioral personal deeply
        4. Learn what works best for this client on call and target`, `Get access to Buying Intent data filters`
      ],
      popular: true
    },
    enterprisePlan: {
      title: 'Enterprise',
        currency_r:'₹',
        currency_d:'$',
        subtitle: 'Solution for big organizations',
        credit:10000,
        unit_d:'0.10',
        unit_r:'8.00',
        total_d: '998',
        total_r: '79998',
        price_d: 199,
        price_r: 15999,
        discount:'80%',
      planBenefits_d: ['Included 10,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `If our existing plan doesn't work for you. Connect Us for your custom requirements. We will make sure that you get the best deal.`],
      planBenefits_r: ['Included 10,000 Credits', 'Credits will be valid for a period of 1 year from the date of purchase', `If our existing plan doesn't work for you. Connect Us for your custom requirements. We will make sure that you get the best deal.`],
      popular: false
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans: 'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.'
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.'
      }
    ]
  }
}

mock.onGet('/pricing-saleasy/data').reply(() => [200, data.pricing])
