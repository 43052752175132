// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios'

export const getAllData = createAsyncThunk('appCampaign/getAllData', async () => {
  const response = await axios.get('v1/campaign')
  return response.data
})

export const appCampaignSlice = createSlice({
  name: 'appCampaign',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload
      })

  
  }
})

export default appCampaignSlice.reducer
