// ** Auth Endpoints
export default {
  loginEndpoint: "/v1/login",
  getCampaign: "/v1/campaign",
  getOrganisation: '/v1/organisation',
  getCampaignFilters: '/v1/campaign/filter',
  registerEndpoint: "/v1/signup",
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: "/jwt/logout",
  googleLoginEndpoint: "/v1/google/validate",
  microsoftLoginEndpoint: "/v1/microsoft/validate",
  googleSignupEndpoint: "/v1/google/signup",
  microsoftSignupEndpoint: "/v1//microsoft/signup",
  updateOrganisation: "/v1/organisation",


  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
