import mock from '../mock'
const data = {
  faqData: {
    // product and services
    productServices: {
      icon: 'Settings',
      title: 'Product & Services',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'What is Saleasy.io?',
          ans: 'Saleasy.io is an AI-powered platform designed for outbound sales. It acts as your virtual employee, connecting with potential leads actively researching and ready to make similar purchases. It streamlines the sales process by automating lead prospecting, outreach, and meeting bookings.'
        },
        {
          question: 'How does it unlock new revenue potential?',
          ans: 'Saleasy AI Autopilot automates various tasks traditionally performed by BDRs. It identifies leads, personalizes outreach, schedules appointments, and even assists in crafting effective sales pitches. The system is designed to continuously learn and improve its performance over time.'
        },
        {
          question: 'What sets Saleasy.io apart in B2B sales?',
          ans: 'Saleasy.io provides a real-time people search engine, enabling you to connect with decision-makers at scale. With over 1 billion verified contact and company records, it offers the fastest way to find B2B sales opportunities. Users experience a significant increase in ROI (5-10x on average) and a 500% increase in opportunities.'
        },
        {
          question: 'How do I get started with Saleasy.io?',
          ans: 'Getting started is easy. Simply define your buyer persona and templates, and Saleasy.io will handle the rest. There is no hassle or code required for setup. You can also run A/B tests to optimize campaigns for higher conversion rates.'
        },
        {
          question: 'Can I customize my experience on Saleasy.io?',
          ans: `Absolutely! Saleasy.io provides an actionable dashboard that can be customized according to your preferences. Centralize all necessary insights for call preparation in one place, making it easy to find the information you need quickly.`
        },
        {
          question: 'Is Saleasy.io suitable for businesses of all sizes?',
          ans: `Yes, Saleasy.io is suitable for businesses of all sizes. Whether you're a startup or an established enterprise, the platform's AI autopilot and virtual BDR capabilities are designed to enhance your outbound sales strategy.`
        },
        {
          question: 'How does Saleasy.io ensure data privacy and security?',
          ans: `Saleasy.io takes data privacy seriously. We adhere to strict Terms of Service and Privacy Policy to safeguard your information. Your data is treated with the utmost confidentiality and is used solely for improving your sales experience.`
        }
      ]
    }
  }
}

mock.onGet('/faq-saleasy/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    filteredData[categoryName] = {
      ...categoryObj,
      qandA: filteredQAndAOfCategory.length ? filteredQAndAOfCategory : []
    }
  })

  return [200, filteredData]
})
